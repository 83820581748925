var sticky_header = function() {

    if (!$("#sticky_controler").length) {
        $(".request_a_quote_button").before('<div id="sticky_controler"></div>');
        $("#sticky_controler").css({ "position": "absolute", "bottom": "0" });
        $("#sticky_controler").height($(".request_a_quote_button").outerHeight(true));
    }

    var sticky_controler = $("#sticky_controler").offset().top,
        header_height = $("#header_main_image").height();

    if (sticky_controler <= $(window).scrollTop()) {
        $(".request_a_quote_button").css({ "position": "fixed", "top": "0" });
    } else {
        $(".request_a_quote_button").css({ "position": "relative", "top": "inherit" }); // Add the css rule first as an object first param is alwasy a key and then the value.
    }

};

if ($(window).width() > 991) {
    $(document).ready(function() {
        $(window).scroll(sticky_header);
        sticky_header();
    });
}

$(".request_a_quote_button .inner_content").on("click", function(e) {
    if ($(".hidden_form_wrapper").css("display") === "none") {
        TweenMax.set('.request_a_quote_button, .home_request_a_quote_button', {
            background: "#1E8BAD"
        });
        TweenMax.set(".close_request_quote_form", {
            opacity: "1"
        });
        TweenMax.set(".request_a_quote_button .inner_content", {
            display: "none"
        });
        TweenMax.set(".hidden_form_wrapper", {
            display: "block"
        });
    }
});

$(".close_request_quote_form").on("click", function() {
    console.log("Clicked on close button");
    TweenMax.set('.request_a_quote_button', {
        background: "#2aace2"
    });
    TweenMax.set(".close_request_quote_form", {
        opacity: "0"
    });
    TweenMax.set(".request_a_quote_button .inner_content", {
        display: "block"
    });
    TweenMax.set(".hidden_form_wrapper", {
        display: "none"
    });
});

$(window).load(function() {
    if ($(window).width() < 992) {
        $(".top_image_wrapper .image_overlay").addClass("center_me");
        $(".top_image_wrapper .image_overlay").removeClass("center_me_vertically");
    }
});

$(window).resize(function() {
    if ($(window).width() < 992) {
        $(".top_image_wrapper .image_overlay").addClass("center_me");
        $(".top_image_wrapper .image_overlay").removeClass("center_me_vertically");
    } else {
        $(".top_image_wrapper .image_overlay").removeClass("center_me");
        $(".top_image_wrapper .image_overlay").addClass("center_me_vertically");
    }
});
