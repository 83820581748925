var can_ajax,
    old_url, site_url = MyAjax.ajaxurl.replace("wp-admin/admin-ajax.php", "");

var ms_ie = false;
var ua = window.navigator.userAgent;
var old_ie = ua.indexOf('MSIE ');
var new_ie = ua.indexOf('Trident/');

if ((old_ie > -1) || (new_ie > -1)) {
    ms_ie = true;
}
if (Modernizr.history && ms_ie !== true) { // ask Modernizr if the browser supports history
    can_ajax = true;
} else {
    can_ajax = false;
}
//////////////////////////////////////////////////

//can_ajax = false;


////////////////////

var xhr, current_url;

if (can_ajax || ms_ie) {
    var $main = $("#ajax_me_wrapper"),
        loadPage = function(href, type) {
            current_url = window.location.href;
            if (xhr && xhr.readystate !== 4) {
                xhr.abort(); // if user clicked twice on a link abort the first
            }
            xhr = $.ajax({
                type: 'GET',
                url: href,
                data: {
                    //MY_REFERER: old_url, NEW_REFERER: href
                },
                /*                                beforeSend: function(xhr) {
                                                    xhr.setRequestHeader("MY_REFERER", old_url);
                                                },*/
                success: function(response) {
                    var split_html = response.split('<body class="');
                    var updated_html = split_html[0] + '<body class="' + "updated_dom " + split_html[1];
                    if (type !== "popstate") {
                        goToByScroll($("#the_page", document), 0, 1.0);
                        /* jshint ignore:start */
                        setTimeout(function() {
                            document.open();
                            document.write(updated_html);
                            document.close();
                        }, 1000);
                        /* jshint ignore:end */
                    } else {
                        /* jshint ignore:start */
                        document.open();
                        document.write(updated_html);
                        document.close();
                        /* jshint ignore:end */
                    }
                },
                error: function(xhr) {
                    if (xhr.statusText !== "abort") { // user just clicked the link twice and we have handled this so its not an error.
                        preloader.active(false);
                        $("body").css("cursor", "auto");
                        //console.log(current_url);
                        history.replaceState({}, '', current_url);
                        swal({
                            title: "Warning",
                            text: "Sorry but there was an error: " + xhr.status + " " + xhr.statusText,
                            showConfirmButton: false,
                            type: "warning",
                            timer: 3800
                        });
                    }
                }
            });
        };


    /*
     * Necessary hack because WebKit fires a popstate event on document load
     * https://code.google.com/p/chromium/issues/detail?id=63040
     * https://bugs.webkit.org/process_bug.cgi
     */
    window.addEventListener('load', function() {
        setTimeout(function() {
            window.addEventListener('popstate', function() {
                /*console.log(window.location);
                console.log(current_url);*/
                if (window.location.href !== current_url) { //if the popstate puts the href back to the original pages url then do nothing.
                    preloader.active(true);
                    loadPage(location.href, "popstate");
                }
            });
        }, 0);
    });
    var start_navigation_away = function(el, url) {

        // send remaining category product IDs over to the product detail page.
        var get_params = "";
        if (window.location.href.indexOf("/product-finder/") > -1) { // if we are leaving a cat page
            if (url.indexOf("/bim/") > -1 || $(el).attr("href").indexOf("/bim/") > -1) { // if we are heading to a product page
                //gather the product IDs from mixitup that are not filtered out.
                get_params = "?related=";
                $("[data-post_id]").each(function(i) {
                    if (i === 7) {
                        return false;
                    } // so 6 not including the one that was clicked...
                    get_params = get_params + $(this).attr("data-post_id") + ".";
                });
                get_params = get_params.slice(0, -1); // remove last period
            }
        }

        if (url !== "") {

            if (window.location.href.indexOf(".dev:3000") > -1 && url.indexOf(":3000") === -1) {
                // if we are on local dev pc add :3000 if its not already part of the url
                url = url.replace(document.location.hostname, document.location.hostname + ":3000");
            }
            preloader.active(true);
            $("body").css("cursor", "progress");
            //console.log(site_url);
            if (url.indexOf(site_url) > -1 || url.indexOf(':') === -1) {
                if (ms_ie) {
                    window.location.href = url + get_params;
                } else {
                    loadPage(url + get_params);
                    //history.state = "navigate";
                    history.pushState({}, '', url + get_params); 
                }
                return false;
            }
        } else {
            var href = $(el).attr("href");
            //console.log(href);

            //if (href !== "#") {
            if (!href.match("^#")) { // if href does not start with hash
                if (window.location.href.indexOf(".dev:3000") > -1 && href.indexOf(":3000") === -1) {
                    // if we are on local dev pc and current url has :3000 add :3000 if its not already part of the links url
                    href = href.replace(document.location.hostname, document.location.hostname + ":3000");
                }
                if (href.indexOf(site_url) === 0 || href.indexOf(':') === -1) {
                    preloader.active(true);
                    $("body").css("cursor", "progress");
                    if (ms_ie) {
                        window.location.href = href + get_params;
                    } else {
                        loadPage(href + get_params);
                        //history.state = "navigate";
                        history.pushState({}, '', href + get_params);
                    }
                } else {
                    window.open(href + get_params, '_blank');
                    preloader.active(false);
                    return false;
                }
            }
        }
    };
    $(document).on("click", "a, area", function() {
        if ($(window).width() < 992 && $(this).is(".is_dropdown .parent_text a")) {
          // stop top level links in the mega menu from navigating away if we are on mobile.
          return false;
        }
        if (this.href === window.location.href) {
            /*                    swal({
                                    title: "That link leads to the same page as you're already on!",
                                    type: "info",
                                    timer: 3000,
                                    showConfirmButton: false
                                });*/
            return false;
        }
        if (!$(this).closest("#wpadminbar").length && !$(this).closest(".woocommerce-product-gallery").length && !$(this).hasClass("festi-cart-remove-product") && !$(this).parent().hasClass("tiled-gallery-item") && !$(this).hasClass("remove") && !$(this).hasClass("ajax_add_to_cart") && !$(this).parent().hasClass("gallery-icon") && !$(this).hasClass("download_button") && !$(this).parent().hasClass("download_button") && $(this).attr("href").indexOf('.pdf') === -1 && $(this).attr("href").indexOf('.zip') === -1 && $(this).attr("href").indexOf('.xls') === -1 && $(this).attr("href").indexOf('.docx') === -1) {
            old_url = window.location.href;
            start_navigation_away(this, "");
            return false;
        } 
    });
}
