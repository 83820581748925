var set_col_hights = function(el, extra) {
    if (typeof extra === "undefined") {
        extra = 0;
    }

    if ($(el).css("min-height")) {
      TweenMax.set($(el), {
        clearProps: "min-height"
      });
    }

    // Get an array of all element heights
    var elementHeights = $(el).map(function() {
        return $(this).height();
    }).get();
    //console.log(elementHeights);
    // Math.max takes a variable number of arguments
    // `apply` is equivalent to passing each height as an argument
    var maxHeight = Math.max.apply(null, elementHeights);

    // Set each height to the max height
    $(el).css("min-height", maxHeight + extra + "px");
};

// how to use...

// $(window).load(function() {
//   if (Modernizr.mq('(min-width: 767px)')) {
//     set_col_hights('.feature_box .body');
//     set_col_hights('.home .output_review .comment');
//     set_col_hights('.blog_feed_heights');
//   }
// });

var do_these_heights = function() {
    if (Modernizr.mq('(min-width: 767px)')) {
        if ($(window).width() > 767) {
            
        }
        if ($(window).width() > 991) {
            set_col_hights($(".services_section .service_col .hidden_content")); 
        }
        // Fix for empty elements getting a height set on them
        $("p, h1, h2, h3, h4").each(function() {
            if ($(this).is(':empty')) {
                $(this).css("display", "none");
            }
        });
    }
};

$(window).load(function() {
    do_these_heights();
}); 

$(window).resize(function() {
    do_these_heights();
});
